<template>
  <section>
      <div class="content-header">
        <h2>Can faculty help students foster academic resilience? </h2>
      </div>
      <div class="content-wrapper">
        <p>Absolutely! As a faculty member, you can help increase students’ academic resilience in a number of ways.</p>
        <div class="accordion">
              <accordion
                headerText="Change mindsets"
                accordionID="collapse01"
              >
                Helping students change their mindset about the malleability of intelligence can change the way they react to setbacks and deal with challenges in the classroom.
              </accordion>
              <accordion
                headerText="Increase social belonging"
                accordionID="collapse02"
              >
                Strengthening students' sense that they belong in the learning environment can alleviate their fears about performance, especially for students who have been historically marginalized or underrepresented in the post-secondary context.
              </accordion>
              <accordion
                headerText="Encourage self-reflection"
                accordionID="collapse03"
              >
                Helping students change their view of their possible selves—for example by coming up with a list of personal values and ranking them in order of importance, by imagining a future "possible self," or by listing the obstacles they might encounter to realizing that self and strategies they would use to overcome the obstacles—can result in lasting and improved academic outcomes, from attendance to initiative to performance.
              </accordion>
              <accordion
                headerText="Increase self-regulation"
                accordionID="collapse04"
              >
                Helping students improve their self-regulation by cultivating goal-setting and self-management strategies can help with the negative thoughts and anxious feelings that are common in school.
              </accordion>
              <accordion
                headerText="Integrate learning strategies "
                accordionID="collapse05"
              >
               <p>Teaching students <strong class="bold">how</strong> to manage their workload in your class, decipher a complex text, chunk a big project into smaller and more manageable sub-tasks, study efficiently and other essential skills can enhance academic performance and resilience.</p>
               <p>Sometimes all it takes is sending the message that all students belong and are capable of great accomplishments with the proper mindset, effort and strategies for success.</p>
              </accordion>
            </div>
            <p class="lm">Learn more</p>
            <p>For more information about helping students foster resilience, check out this podcast and accompanying article on <a href="https://blogs.scientificamerican.com/beautiful-minds/why-academic-tenacity-matters/" target="_blank">Why Academic Tenacity Matters</a> from Scientific American.</p>
      </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
